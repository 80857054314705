import React from 'react';

const BluesignCallout = ({ ...props }) => {
    return (
        <svg
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7881 15.2881C20.1723 10.904 27.2735 10.904 31.6577 15.2881L15.7881 31.1577C11.404 26.7735 11.404 19.6723 15.7881 15.2881ZM49.2118 15.2881C44.8276 10.904 37.7264 10.904 33.3422 15.2881L49.2118 31.1577C53.5959 26.7735 53.5959 19.6723 49.2118 15.2881ZM32.5066 43.2224C38.7038 43.2224 43.7275 38.1986 43.7275 32.0015C43.7275 25.8044 38.7038 20.7806 32.5066 20.7806C26.3095 20.7806 21.2857 25.8044 21.2857 32.0015C21.2857 38.1986 26.3095 43.2224 32.5066 43.2224ZM15.7881 48.7103C20.1723 53.0944 27.2735 53.0944 31.6577 48.7103L15.7881 32.8407C11.404 37.2249 11.404 44.3261 15.7881 48.7103ZM49.2118 48.7103C44.8276 53.0944 37.7264 53.0944 33.3422 48.7103L49.2118 32.8407C53.5959 37.2249 53.5959 44.3261 49.2118 48.7103Z"
                fill="#222222"
            />
        </svg>
    );
};

export default BluesignCallout;
