import React from 'react';
import AntiBacterial from './AntiBacterial';
import AntiOdour from './AntiOdour';
import Bag from './Bag';
import Bluesign from './Bluesign';
import BluesignCallout from './BluesignCallout';
import BookmarkFilled from './BookmarkFilled';
import BookmarkDisabled from './BookmarkDisabled';
import BookmarkUnfilled from './BookmarkUnfilled';
import Brand from './Brand';
import Breathable from './Breathable';
import Check from './Check';
import Circle from './Circle';
import Close from './Close';
import CompressionFabrics from './CompressionFabrics';
import CrashReplacement from './CrashReplacement';
import CrashReplacementSmall from './CrashReplacementSmall';
import Discover from './Discover';
import Dot from './Dot';
import Down from './Down';
import DownFill from './DownFill';
import DownVector from './DownVector';
import DriReleaseFabric from './DriReleaseFabric';
import DWRCoating from './DWRCoating';
import FlagAU from './FlagAU';
import FlagEU from './FlagEU';
import FlagHK from './FlagHK';
import FlagKOR from './FlagKOR';
import FlagROW from './FlagROW';
import FlagSG from './FlagSG';
import FlagTW from './FlagTW';
import FlagUK from './FlagUK';
import FlagUS from './FlagUS';
import FourWayStretch from './FourWayStretch';
import FreeReturns from './FreeReturns';
import FreeReturnsSmall from './FreeReturnsSmall';
import FreeShipping from './FreeShipping';
import Globe from './Globe';
import Insulated from './Insulated';
import ItalianFabric from './ItalianFabric';
import Left from './Left';
import Lightweight from './Lightweight';
import Logo from './Logo';
import MAAPHoneycomb from './MAAPHoneycomb';
import Menu from './Menu';
import Minus from './Minus';
import MoistureWicking from './MoistureWicking';
import Northeast from './Northeast';
import OrganicCotton from './OrganicCotton';
import Packable from './Packable';
import Plus from './Plus';
import Profile from './Profile';
import QuickDrying from './QuickDrying';
import RecycledYarns from './RecycledYarns';
import ReflectiveGraphics from './ReflectiveGraphics';
import Right from './Right';
import Search from './Search';
import SPF50Protection from './SPF50Protection';
import Star from './Star';
import Stowable from './Stowable';
import TapedSeams from './TapedSeams';
import ThermalRegulation from './ThermalRegulation';
import TouchscreenConductive from './TouchscreenConductive';
import TrueFit from './TrueFit';
import { IconGeneratorType, IconMapping, IconName } from './types';
import UltraLightweight from './UltraLightweight';
import Waterproof from './Waterproof';
import Windproof from './Windproof';

export {
    AntiBacterial,
    AntiOdour,
    Bag,
    Bluesign,
    BookmarkFilled,
    BookmarkUnfilled,
    BookmarkDisabled,
    Brand,
    Breathable,
    Check,
    Circle,
    Close,
    CompressionFabrics,
    Discover,
    Dot,
    DownFill,
    DownVector,
    DriReleaseFabric,
    DWRCoating,
    FlagAU,
    FlagEU,
    FlagHK,
    FlagKOR,
    FlagROW,
    FlagSG,
    FlagTW,
    FlagUK,
    FlagUS,
    FourWayStretch,
    FreeShipping,
    Globe,
    Insulated,
    ItalianFabric,
    Left,
    Lightweight,
    Logo,
    MAAPHoneycomb,
    Menu,
    Minus,
    MoistureWicking,
    Northeast,
    Packable,
    Plus,
    Profile,
    QuickDrying,
    RecycledYarns,
    ReflectiveGraphics,
    Right,
    Search,
    SPF50Protection,
    Star,
    Stowable,
    TapedSeams,
    ThermalRegulation,
    TouchscreenConductive,
    TrueFit,
    UltraLightweight,
    Waterproof,
    Windproof,
};

const icons: IconMapping = {
    ['globe']: Globe,
    ['discover']: Discover,
    ['down']: Down,
    ['northeast']: Northeast,
    ['right']: Right,
    ['left']: Left,
    ['logo']: Logo,
    ['profile']: Profile,
    ['bag']: Bag,
    ['menu']: Menu,
    ['close']: Close,
    ['search']: Search,
    ['bookmarkfilled']: BookmarkFilled,
    ['bookmarkunfilled']: BookmarkUnfilled,
    ['bookmarkdisabled']: BookmarkDisabled,
    ['check']: Check,
    ['plus']: Plus,
    ['minus']: Minus,
    ['dot']: Dot,
    ['moisture-wicking']: MoistureWicking,
    ['dri-release-fabric']: DriReleaseFabric,
    ['dwr-coating']: DWRCoating,
    ['italian-fabric']: ItalianFabric,
    ['breathable']: Breathable,
    ['anti-bacterial']: AntiBacterial,
    ['anti-odour']: AntiOdour,
    ['bluesign']: Bluesign,
    ['compression']: CompressionFabrics,
    ['down-fill']: DownFill,
    ['four-way-stretch']: FourWayStretch,
    ['insulated']: Insulated,
    ['lightweight']: Lightweight,
    ['maap-honeycomb']: MAAPHoneycomb,
    ['packable']: Packable,
    ['quick-drying']: QuickDrying,
    ['recycled-yarns']: RecycledYarns,
    ['reflective-graphics']: ReflectiveGraphics,
    ['spf-50-protection']: SPF50Protection,
    ['stowable']: Stowable,
    ['taped-seams']: TapedSeams,
    ['thermal-regulation']: ThermalRegulation,
    ['touchscreen-conductive']: TouchscreenConductive,
    ['ultra-lightweight']: UltraLightweight,
    ['waterproof']: Waterproof,
    ['windproof']: Windproof,
    ['down-vector']: DownVector,
    ['circle']: Circle,
    ['free-shipping']: FreeShipping,
    ['free-returns']: FreeReturns,
    ['free-returns-small']: FreeReturnsSmall,
    ['crash-replacement']: CrashReplacement,
    ['crash-replacement-small']: CrashReplacementSmall,
    ['blue-sign']: BluesignCallout,
    ['flag-au']: FlagAU,
    ['flag-us']: FlagUS,
    ['flag-eu']: FlagEU,
    ['flag-uk']: FlagUK,
    ['flag-kor']: FlagKOR,
    ['flag-row']: FlagROW,
    ['flag-sg']: FlagSG,
    ['flag-tw']: FlagTW,
    ['flag-hk']: FlagHK,
    ['star']: Star,
    ['brand']: Brand,
    ['organic-cotton']: OrganicCotton,
    ['truefit']: TrueFit,
};

interface IconProps {
    icon: IconName;
    className?: string;
}

export const Icon: React.FC<IconProps> = ({ icon, className, ...rest }) => {
    if (!icon) {
        return null;
    }

    const ComponentIcon = icons[icon];
    if (!ComponentIcon) {
        return null;
    }
    const IconComponent = ComponentIcon as IconGeneratorType;
    return <IconComponent className={className} {...rest} />;
};

export default Icon;
