import * as React from 'react';

const Logo = ({ ...props }) => (
    <svg
        width="76"
        height="20"
        viewBox="0 0 76 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2496 19.9554L40.9313 19.9586L52.2013 0.00320055L55.8587 0L52.0714 19.9426L48.639 19.949L49.4821 15.9995L46.3603 16.0027L44.2496 19.9554ZM48.0464 12.879L50.1571 12.8758L51.5928 6.31148L48.0464 12.879ZM71.5021 0.0190467L68.4342 0.0222473L60.9293 19.9169L64.3617 19.9137L66.7196 13.663H66.7481C70.3326 13.4037 73.6128 11.269 75.3084 6.77861L75.3306 6.721C77.0864 2.0578 75.2418 0.0158462 71.5021 0.0190467ZM71.8507 6.83622L71.8285 6.89383C70.9189 9.30704 69.423 10.36 67.9588 10.4496H67.9303L70.6559 3.2324L70.7668 3.2292C72.2564 3.2292 72.8046 4.30778 71.8507 6.83622ZM24.3655 19.9967L21.0472 19.9999L32.3172 0.0412865L35.9746 0.0380859L32.1873 19.9839L28.7549 19.9903L29.598 16.0408L26.4731 16.044L24.3655 19.9967ZM28.1623 12.9203L30.273 12.9171L31.7087 6.35277L28.1623 12.9203ZM10.9119 0.0477345L10.5854 7.32258L15.7482 0.0413334L19.152 0.0381329L11.6471 19.9327L8.35424 19.9391L12.8927 7.91468L7.54607 15.452L7.48903 15.4488L7.82814 7.92109L3.29289 19.9424L0 19.9488L7.50804 0.0541356L10.9119 0.0477345Z"
            fill="currentColor"
        />
    </svg>
);

export default Logo;
