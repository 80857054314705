import React from 'react';

const Star = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 4L9.79611 9.52786H15.6085L10.9062 12.9443L12.7023 18.4721L8 15.0557L3.29772 18.4721L5.09383 12.9443L0.391548 9.52786H6.20389L8 4Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Star;
