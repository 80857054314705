const Brand = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        {...props}
    >
        <circle cx="32" cy="32" r="32" fill="white" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.7835 37.9732L33.775 37.9751L40.5964 26.0019L42.8101 26L40.5177 37.9655L38.4403 37.9694L38.9505 35.5997L37.061 35.6016L35.7835 37.9732ZM38.0815 33.7274L39.3591 33.7255L40.2281 29.7869L38.0815 33.7274ZM52.2779 26.0119L50.4211 26.0138L45.8786 37.9505L47.9561 37.9486L49.3833 34.1982H49.4005C51.5701 34.0427 53.5555 32.7618 54.5818 30.0676L54.5952 30.033C55.6579 27.2351 54.5415 26.0099 52.2779 26.0119ZM52.4889 30.1022L52.4755 30.1367C51.925 31.5846 51.0196 32.2164 50.1333 32.2702H50.1161L51.7658 27.9399L51.8329 27.938C52.7345 27.938 53.0663 28.5851 52.4889 30.1022ZM15.6046 26.0286L15.407 30.3935L18.5319 26.0248L20.5921 26.0229L16.0496 37.9596L14.0565 37.9635L16.8035 30.7488L13.5674 35.2712L13.5329 35.2692L13.7381 30.7526L10.9931 37.9654L9 37.9692L13.5444 26.0325L15.6046 26.0286ZM23.7477 37.998L21.7393 37.9999L28.5606 26.0248L30.7743 26.0229L28.482 37.9903L26.4045 37.9942L26.9147 35.6245L25.0233 35.6264L23.7477 37.998ZM26.0458 33.7522L27.3233 33.7503L28.1923 29.8117L26.0458 33.7522Z"
            fill="black"
        />
    </svg>
);

export default Brand;
