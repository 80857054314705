import React from 'react';

const Windproof = ({ props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1371 4.8135L13.4894 3H30L29.6476 4.8135H13.1371ZM2.67955 25.542H19.1901L19.5488 23.7285H3.0382L2.67955 25.542ZM2 29H18.5106L18.8629 27.1865H2.35236L2 29ZM12.4638 8.2715H28.9744L29.3267 6.458H12.8162L12.4638 8.2715ZM11.4193 13.3675L12.1492 9.9095H22.3928H28.6598L28.3074 11.723H22.0153L21.6755 13.3675H27.9865L27.6342 15.181H21.298L20.9582 16.819H27.307L26.9483 18.6325H20.5807L19.8634 22.0905H9.58202H3.35281L3.71146 20.277H9.95955L10.3056 18.6325H4.02607L4.37843 16.819H10.6894L11.0355 15.181H4.69933L5.05169 13.3675H11.4193Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Windproof;
