import React from 'react';

const MoistureWicking = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M28.2509 13.3029C27.2367 13.3029 23.0996 15.4907 18.0907 15.9159C18.073 15.4021 17.8683 14.9327 17.5391 14.5784C21.4004 11.372 25.8932 10.0345 26.6139 9.3171C27.6904 8.24535 27.6993 6.5093 26.6317 5.43756C25.5641 4.35695 23.8203 4.35695 22.7527 5.41984C22.032 6.13729 20.6619 10.6103 17.4235 14.4544C17.0676 14.1267 16.605 13.9229 16.089 13.8964C16.5516 8.89194 18.7758 4.77325 18.7847 3.75465C18.7847 2.24004 17.5658 1 16.0445 1C14.532 1 13.2954 2.21346 13.2865 3.73694C13.2865 4.75554 15.484 8.89194 15.911 13.8964C15.395 13.9141 14.9324 14.1178 14.5765 14.4455C11.3648 10.5748 10.0302 6.093 9.30961 5.3667C8.24199 4.28609 6.49822 4.28609 5.4306 5.34898C4.35409 6.42073 4.3452 8.15678 5.41281 9.23738C6.13345 9.96369 10.6085 11.3366 14.452 14.5784C14.1228 14.9327 13.9181 15.3933 13.8915 15.907C8.89146 15.4376 4.77224 13.2143 3.74911 13.2055C2.23665 13.1878 1 14.419 1 15.9336C1 17.4482 2.21886 18.6882 3.74021 18.6882C4.75445 18.6882 8.89146 16.5004 13.9004 16.0753C13.9181 16.589 14.1228 17.0585 14.452 17.4128C10.5907 20.6191 6.09786 21.9566 5.37722 22.674C4.30071 23.7458 4.29181 25.4818 5.35943 26.5624C6.42705 27.643 8.17082 27.643 9.23843 26.5802C9.95907 25.8627 11.3292 21.3897 14.5676 17.5456C14.9235 17.8733 15.3861 18.0771 15.9021 18.1036C15.4395 23.1081 13.2153 27.2268 13.2064 28.2454C13.2064 29.76 14.4253 31 15.9466 31C17.4591 31 18.6957 29.7865 18.7046 28.2631C18.7046 27.2445 16.5071 23.1081 16.0801 18.1036C16.5961 18.0859 17.0587 17.8822 17.4146 17.5545C20.6263 21.4252 21.9609 25.907 22.6815 26.6333C23.7491 27.7139 25.4929 27.7139 26.5605 26.651C27.637 25.5793 27.6459 23.8432 26.5783 22.7626C25.8577 22.0363 21.3826 20.6634 17.5391 17.4216C17.8683 17.0673 18.073 16.6067 18.0996 16.093C23.0996 16.5624 27.2189 18.7857 28.242 18.7945C29.7544 18.7945 30.9911 17.581 31 16.0576C31 14.543 29.7811 13.3029 28.2598 13.3029H28.2509Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default MoistureWicking;
