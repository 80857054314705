const FlagHK = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 0V12H16V0H0Z"
                fill="#EA1A1A"
            />
            <g filter="url(#filter0_d_7508_22085)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99134 5.80997C7.99134 5.80997 5.29194 3.40264 8.51315 2C8.51315 2 9.44993 3.03348 8.74716 4.19371C8.51659 4.57425 8.32214 4.84598 8.176 5.05018C7.8766 5.46856 7.78003 5.6035 7.99134 5.80997ZM4.0074 4.08149C3.82201 7.5899 7.18219 6.25165 7.18219 6.25165C6.90316 6.3483 6.82562 6.2016 6.58531 5.74692C6.46797 5.5249 6.31181 5.22944 6.07443 4.85303C5.35085 3.70562 4.0074 4.08149 4.0074 4.08149ZM9.0957 5.9276C9.0957 5.9276 12.5608 6.96496 10.237 9.6C10.237 9.6 8.95049 9.06047 9.0957 7.71189C9.14335 7.26969 9.20436 6.94125 9.25022 6.69439C9.3442 6.18842 9.37453 6.02518 9.0957 5.9276ZM8.18494 6.73637C8.18494 6.73637 8.80613 10.2996 5.41029 9.39908C5.41029 9.39908 5.31784 8.00717 6.58904 7.53417C7.006 7.379 7.32712 7.2867 7.56844 7.21734L7.56846 7.21733L7.56849 7.21732L7.56849 7.21732C8.0629 7.07521 8.22235 7.02938 8.18494 6.73637ZM12.4716 5.20411C10.3476 2.40563 8.64609 5.59723 8.64609 5.59723C8.7968 5.34321 8.95072 5.40515 9.42773 5.59711C9.66073 5.69088 9.97081 5.81567 10.3953 5.9498C11.6886 6.35856 12.4716 5.20411 12.4716 5.20411Z"
                    fill="white"
                />
            </g>
            <path
                d="M5.3299 5.17276C5.3299 5.17276 6.00866 6.241 7.12022 6.241"
                stroke="#EA1A1A"
                stroke-width="0.625"
            />
            <path
                d="M7.987 3.70042C7.987 3.70042 7.37786 4.82418 7.89971 5.80564"
                stroke="#EA1A1A"
                stroke-width="0.625"
            />
            <path
                d="M10.9009 4.96458C10.9009 4.96458 9.47878 4.74899 8.735 5.57505"
                stroke="#EA1A1A"
                stroke-width="0.625"
            />
            <path
                d="M10.0703 7.56779C10.0703 7.56779 9.78645 6.15769 8.75582 5.74129"
                stroke="#EA1A1A"
                stroke-width="0.625"
            />
            <path
                d="M7.08856 8.33322C7.08856 8.33322 8.31656 7.58425 8.35535 6.47336"
                stroke="#EA1A1A"
                stroke-width="0.625"
            />
            <defs>
                <filter
                    id="filter0_d_7508_22085"
                    x="3"
                    y="1"
                    width="10.4716"
                    height="9.59999"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.6 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_7508_22085"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_7508_22085"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default FlagHK;
