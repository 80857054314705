import React from 'react';

const QuickDrying = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.2 13.4C11.0719 13.4 13.4 11.0719 13.4 8.2C13.4 5.32812 11.0719 3 8.2 3C5.32812 3 3 5.32812 3 8.2C3 11.0719 5.32812 13.4 8.2 13.4ZM23.8 29C26.6719 29 29 26.6719 29 23.8C29 20.9281 26.6719 18.6 23.8 18.6C20.9281 18.6 18.6 20.9281 18.6 23.8C18.6 26.6719 20.9281 29 23.8 29ZM23.8 3C26.6716 3 29 5.32844 29 8.2C29 11.0716 26.6716 13.4 23.8 13.4C18.0569 13.4 13.4 18.0569 13.4 23.8C13.4 26.6716 11.0716 29 8.2 29C5.32844 29 3 26.6716 3 23.8C3 20.9284 5.32844 18.6 8.2 18.6C13.9431 18.6 18.6 13.9431 18.6 8.2C18.6 5.32844 20.9284 3 23.8 3Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default QuickDrying;
