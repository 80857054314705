import React from 'react';
import s from './StaticImage.module.css';
import cn from 'clsx';

interface StaticImageProps {
    desktopImage?: string;
    mobileImage?: string;
    altDescription?: string;
    desktopWidth?: string | number;
    desktopHeight?: string | number;
    mobileWidth?: string | number;
    mobileHeight?: string | number;
    fullWidth?: boolean;
    adjustAspectRatio?: boolean;
    urlTransformer?: (url: string) => string;
    classname?: string;
}

const defaultUrlTransformer = (url: string) => url;

export const StaticImage = ({
    desktopImage,
    mobileImage,
    altDescription,
    desktopWidth,
    desktopHeight,
    mobileWidth,
    mobileHeight,
    fullWidth,
    adjustAspectRatio,
    urlTransformer,
    classname,
}: StaticImageProps) => {
    urlTransformer = urlTransformer || defaultUrlTransformer;
    return (
        <>
            {desktopImage && (
                <div className={s.desktopImage}>
                    <img
                        src={urlTransformer(desktopImage)}
                        alt={`Image for ${altDescription}`}
                        height={desktopHeight}
                        width={desktopWidth}
                        className={cn(
                            fullWidth ? s.fullWidth : s.root,
                            adjustAspectRatio ? s.adjustAspectRatio : '',
                            classname
                        )}
                    />
                </div>
            )}
            {mobileImage && (
                <div className={s.mobileImage}>
                    <img
                        src={urlTransformer(mobileImage)}
                        alt={`Image for ${altDescription}`}
                        height={mobileHeight}
                        width={mobileWidth}
                        className={cn(
                            fullWidth ? s.fullWidth : s.root,
                            adjustAspectRatio ? s.adjustAspectRatio : '',
                            classname
                        )}
                    />
                </div>
            )}
        </>
    );
};

export default StaticImage;
