import React from 'react';

const SPF50Protection = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 3V15.1451H25.2857V16.8549H29V29H16.8549V25.2857H15.1451V29H3V16.8549H6.71429V15.1451H3V3H15.1451V6.71429H16.8549V3H29ZM21.6697 20.4512C22.6327 19.2263 23.2157 17.6803 23.2157 15.9967C23.2157 14.3132 22.6392 12.7672 21.6697 11.5422L26.2094 7.00252L25.004 5.79718L20.4643 10.3369C19.2394 9.3739 17.6934 8.79088 16.0098 8.79088C14.3263 8.79088 12.7803 9.36735 11.5553 10.3369L7.01562 5.79718L5.81028 7.00252L10.35 11.5422C9.387 12.7672 8.80398 14.3132 8.80398 15.9967C8.80398 17.6803 9.38045 19.2263 10.35 20.4512L5.81028 24.9909L7.01562 26.1963L11.5553 21.6566C12.7803 22.6196 14.3263 23.2026 16.0098 23.2026C17.6934 23.2026 19.2394 22.6261 20.4643 21.6566L25.004 26.1963L26.2094 24.9909L21.6697 20.4512ZM13.7564 16.0033C13.7564 14.7652 14.7586 13.7629 15.9967 13.7629C17.2348 13.7629 18.2371 14.7652 18.2371 16.0033C18.2371 17.2414 17.2348 18.2436 15.9967 18.2436C14.7586 18.2436 13.7564 17.2414 13.7564 16.0033Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SPF50Protection;
