'use client';

import cn from 'clsx';
import s from './PatternLanguageIntro.module.css';
import { usePathname } from 'next/navigation';

export type LanguageIntroProps = {
    context: string | null;
    sub_context?: string | null;
    content: string | null;
    border?: boolean;
    narrow?: boolean;
};

export const PatternLanguageIntro = ({
    context,
    sub_context,
    content,
    border = true,
    narrow = false,
}: LanguageIntroProps) => {
    if (!content) return null;
    const pathname = usePathname();
    const hasBundlesPath = pathname.startsWith('/bundles');
    return (
        <div
            className={cn(s.root, s.stack, {
                [s.border]: border,
                [s.narrow]: narrow,
            })}
        >
            <div className={s.header}>
                {hasBundlesPath ? <h1 className="mmds-component-one-detail">{context}</h1> :
                    <h3 className="mmds-component-one-detail">{context}</h3>}
                {sub_context && (
                    <p className={cn(s.subCount, 'mmds-copy-three-detail')}>
                        {sub_context}
                    </p>
                )}
            </div>
            <p className={cn(s.description, 'mmds-subtitle-one')}>{content}</p>
        </div>
    );
};

export default PatternLanguageIntro;
