'use client';

import React, { useEffect } from 'react';
import { LinkerComponent, LinkManager } from '../link-manager/LinkManager';
import { NavIconName } from '../../tokens/icons/types';
import PatternPillCluster, {
    PillClusterPillInput,
} from '../../patterns/pill-cluster/PatternPillCluster';
import { Icon } from '../../tokens/icons';
import Skeleton from '../skeleton';
import { Button, ButtonProps } from '../button';
import cn from 'clsx';
// @ts-ignore
import s from './NavItem.module.css';

type NavItemVariantType = 'l1' | 'l2';

export type NavItemProps = {
    variant?: NavItemVariantType;
    label: string;
    children?: React.ReactNode;
    icon?: NavIconName;
    count?: number;
    countResolver?: (handle: string) => Promise<number>;
    hrefLink?: string;
    cluster?: PillClusterPillInput[];
    active?: boolean;
    open?: boolean;
    onSelect?: () => void;
    openCluster?: null | string;
    className?: string;
    lang?: string;
    type?: 'base' | 'footer';
    Linker?: LinkerComponent;
};

export const NavItem = ({
    variant,
    label,
    children,
    icon,
    count,
    countResolver,
    hrefLink,
    cluster,
    active,
    open,
    onSelect,
    openCluster,
    className,
    lang,
    type = 'base',
    Linker = LinkManager,
}: NavItemProps) => {
    const [resolvedCount, setResolvedCount] = React.useState<number | null>(
        null
    );
    const rootClasses: string = cn(
        s.root,
        'mmds-title-four',
        {
            [s.footerRoot]: type === 'footer',
            [s.inactive]: !active,
            [s.active]: active,
        },
        className
    );
    const subItemsContainerClasses: string = cn(s.subItemsContainer, {
        open: open,
    });
    const openConsentWidget = () => {
        return (window as any).Osano.cm.showDrawer(
            'osano-cm-dom-info-dialog-open'
        );
    };

    useEffect(() => {
        if (countResolver && hrefLink) {
            const handle: string | undefined = hrefLink.split('/').pop();
            if (!handle) return;

            countResolver(handle).then((count: number) => {
                setResolvedCount(count);
            });
        }
    }, [countResolver]);

    return (
        <div className={rootClasses} onClick={onSelect}>
            <div className={s.label}>
                {label}
                {!!icon && (
                    <div className={cn(s.icon, { [s.active]: open })}>
                        <Icon icon={icon} />
                    </div>
                )}
                {!icon && countResolver && !count && (
                    <Skeleton width="20px" height="15px" />
                )}
                {!icon && (count || resolvedCount) && (
                    <div className="mmds-copy-three-detail">
                        {count || resolvedCount}
                    </div>
                )}
            </div>
            {type === 'base' ? (
                <div className={subItemsContainerClasses}>
                    {open && cluster && lang && (
                        <div className={s.subItems}>
                            <PatternPillCluster
                                cluster={cluster}
                                lang={lang}
                                Linker={Linker}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={subItemsContainerClasses}>
                    {open && cluster && (
                        <div className={s.subItems}>
                            {cluster.map((item) => {
                                const commonProps: ButtonProps = {
                                    label: item.label,
                                    variant: 'quite',
                                    theme: 'light',
                                    padding: 'zeroInline',
                                    lang: lang,
                                };
                                const renderButton = () => {
                                    if (item.label === 'Cookie Preferences') {
                                        return (
                                            <Button
                                                {...commonProps}
                                                onClick={openConsentWidget}
                                            />
                                        );
                                    }
                                    if (
                                        item.label === 'Email Us' ||
                                        item.label.match('Phone')
                                    ) {
                                        return (
                                            <Button
                                                {...commonProps}
                                                icon="circle"
                                                hrefLink={item.hrefLink}
                                                target={item.target}
                                            />
                                        );
                                    }
                                    return (
                                        <Button
                                            {...commonProps}
                                            hrefLink={item.hrefLink}
                                            target={item.target}
                                            Linker={Linker}
                                        />
                                    );
                                };

                                return (
                                    <div
                                        className={cn('mmds-component-one')}
                                        key={item.label}
                                    >
                                        {renderButton()}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NavItem;
