import React from 'react';

const TrueFit = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group 30">
                <rect
                    id="Rectangle 949"
                    width="16"
                    height="16"
                    rx="2"
                    fill="#C3364E"
                />
                <g id="Group 29">
                    <rect
                        id="Rectangle 950"
                        x="3.07715"
                        y="2.46152"
                        width="9.84615"
                        height="1.84615"
                        fill="white"
                    />
                    <rect
                        id="Rectangle 951"
                        x="6.76953"
                        y="2.46152"
                        width="2.46154"
                        height="11.0769"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
};

export default TrueFit;
