import React from 'react';

const BookmarkFilled = ({ ...props }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.5 20C0.5 9.23045 9.23045 0.500004 20 0.500004C30.7696 0.500004 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z"
                fill="white"
            />
            <path
                d="M0.5 20C0.5 9.23045 9.23045 0.500004 20 0.500004C30.7696 0.500004 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z"
                stroke="#222222"
            />
            <path d="M26 13H14V27L20 23L26 27V13Z" fill="#222222" />
        </svg>
    );
};

export default BookmarkFilled;
