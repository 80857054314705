import React from 'react';

const FlagKOR = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>Korean Flag</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0V12H16V0H0Z"
                fill="#F7F7F7"
            />
            <mask
                id="mask0_5951_16834"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0V12H16V0H0Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_5951_16834)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99999 9.10974C9.60135 9.10974 10.8995 7.7311 10.8995 6.03047C10.8995 4.32984 9.60135 2.9512 7.99999 2.9512C6.39862 2.9512 5.10046 4.32984 5.10046 6.03047C5.10046 7.7311 6.39862 9.10974 7.99999 9.10974Z"
                    fill="#3D58DB"
                />
                <mask
                    id="mask1_5951_16834"
                    maskUnits="userSpaceOnUse"
                    x="5"
                    y="2"
                    width="6"
                    height="8"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99999 9.10974C9.60135 9.10974 10.8995 7.7311 10.8995 6.03047C10.8995 4.32984 9.60135 2.9512 7.99999 2.9512C6.39862 2.9512 5.10046 4.32984 5.10046 6.03047C5.10046 7.7311 6.39862 9.10974 7.99999 9.10974Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_5951_16834)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.0009 6.26635C11.0009 6.26635 10.8382 4.81437 9.63284 4.73555C8.42751 4.65673 8.10251 5.80034 8.03296 6.10333C7.9634 6.40631 7.78736 7.21456 6.51828 7.21456C5.24921 7.21456 5.13474 5.15935 5.13474 5.15935V2.73593H11.0009V6.26635Z"
                        fill="#E31D1C"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.64967 1.02608L4.10715 1.43984L1.98702 3.91579L1.52954 3.50203L3.64967 1.02608ZM4.40416 1.69565L4.86165 2.10942L2.81752 4.5495L2.36003 4.13574L4.40416 1.69565ZM5.61269 2.78725L5.15521 2.37349L3.1054 4.83209L3.56288 5.24585L5.61269 2.78725Z"
                    fill="#272727"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6819 0.852585L12.2243 1.2648L13.018 2.19622L13.4756 1.784L12.6819 0.852585ZM14.0927 2.5659L13.6351 2.97812L14.4288 3.90953L14.8864 3.49732L14.0927 2.5659ZM10.714 2.60639L11.1716 2.19417L11.9653 3.12559L11.5077 3.53781L10.714 2.60639ZM12.5824 3.90749L12.1248 4.31971L12.9185 5.25112L13.3761 4.83891L12.5824 3.90749ZM11.4978 1.91559L11.9554 1.50338L14.1647 4.19381L13.7071 4.60602L11.4978 1.91559Z"
                    fill="#272727"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0275 7.12156L12.566 6.70773L11.7656 7.64278L12.2271 8.05661L13.0275 7.12156ZM11.6818 8.76706L11.2204 8.35324L10.42 9.28829L10.8814 9.70211L11.6818 8.76706ZM14.0233 8.01641L14.4847 8.43024L13.6843 9.36529L13.2229 8.95146L14.0233 8.01641ZM13.2048 10.1139L12.7434 9.70007L11.943 10.6351L12.4044 11.0489L13.2048 10.1139ZM11.9833 8.98902L12.4447 9.40284L11.6092 10.4013L11.1478 9.98749L11.9833 8.98902ZM13.8154 7.73912L13.354 7.3253L12.5185 8.32377L12.9799 8.73759L13.8154 7.73912Z"
                    fill="#272727"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.20329 7.44493L3.66089 7.03226L5.70319 9.37246L5.24558 9.78513L3.20329 7.44493ZM3.67967 9.49177L4.13727 9.0791L4.99993 10.0269L4.54232 10.4395L3.67967 9.49177ZM2.15472 8.34539L1.69711 8.75806L3.75001 11.1297L4.20762 10.7171L2.15472 8.34539ZM2.4803 8.07608L2.93791 7.66342L3.68574 8.54035L3.22814 8.95302L2.4803 8.07608Z"
                    fill="#272727"
                />
            </g>
        </svg>
    );
};
export default FlagKOR;
