'use client';

import React, { ReactNode } from 'react';
import StaticImage from '../static-image';
import { LinkerComponent, LinkManager } from '../link-manager/LinkManager';
import { DownVector } from '../../tokens/icons';
import { Button } from '../button';
import cn from 'clsx';
import s from './cart-line.module.css';
import Bookmark from '../../tokens/icons/Bookmark';

export type CartLineProps = {
    id: string;
    handle: string;
    sku: string;
    title: string;
    price: string;
    currencyCode: string;
    quantity: number;
    compareAtPrice: null | string;
    imageSrc: string;
    size: string;
    colour: string;
    discountMessage?: string | null;
    bundleID: null | string;
    giftID: null | string;
    isUserInCreditShoppingMode: boolean;
    callbacks: {
        removeFromCart: (lineId: string) => void;
        removeBundle: (bundleId: string) => void;
        removeGift: (giftID: string) => void;
        addToWishlist: (sku: string, handle: string) => void;
        updateQuantity: (lineId: string, quantity: string) => void;
    };
    compact?: boolean;
    lang: string;
    Linker: LinkerComponent;
    errorMessages: { [key: string]: string | null };
};

export function CartLine({
    id,
    handle,
    sku,
    title,
    price,
    currencyCode,
    quantity,
    compareAtPrice,
    imageSrc,
    size,
    colour,
    discountMessage,
    bundleID,
    giftID,
    isUserInCreditShoppingMode,
    callbacks,
    compact = false,
    lang,
    Linker = LinkManager,
    errorMessages,
}: CartLineProps) {
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const selectOptionsJSX: ReactNode[] = [];
    for (let i = 1; i <= 10; i++) {
        selectOptionsJSX.push(
            <option key={i} value={i} selected={quantity === i}>
                {i}
            </option>
        );
    }
    const rootClassNames = cn(s.root, { [s.compact]: compact });
    const imageDimensions = compact
        ? { width: '80', height: '107' }
        : { width: '160', height: '225' };

    const imageResolutionDimensions = compact
        ? { width: '240', height: '321' }
        : { width: '480', height: '675' };

    const imageDimensionsString = new URLSearchParams(
        imageResolutionDimensions
    ).toString();

    const toMobileRatio = (value: string): string => {
        if (compact) return value;
        const output = parseInt(value) * 0.8;
        return output.toString();
    };
    return (
        <div
            className={cn(rootClassNames, 'nosto_element')}
            data-sku={sku}
            data-lineitem-id={id}
        >
            <Linker
                className={s.imageLink}
                href={`/products/${handle}`}
                lang={lang}
                title={`Go to ${title}`}
                passthrough={!!giftID}
            >
                <StaticImage
                    desktopImage={`${imageSrc}?${imageDimensionsString}`}
                    mobileImage={`${imageSrc}?${imageDimensionsString}`}
                    altDescription={title}
                    desktopWidth={imageDimensions.width}
                    desktopHeight={imageDimensions.height}
                    mobileWidth={toMobileRatio(imageDimensions.width)}
                    mobileHeight={toMobileRatio(imageDimensions.height)}
                />
            </Linker>
            <div className={s.details}>
                <div className={s.variantWrapper}>
                    <div className={s.variant}>
                        <div
                            className={cn(
                                'mmds-component-one-detail',
                                s.variantRow
                            )}
                        >
                            <Linker
                                href={`/products/${handle}`}
                                lang={lang}
                                title={`Go to ${title}`}
                                className={cn(
                                    {
                                        [s.compact]: compact,
                                    },
                                    'mmds-component-one-detail'
                                )}
                                passthrough={!!giftID}
                            >
                                {title}
                            </Linker>
                        </div>
                        <div
                            className={cn(
                                'mmds-component-one-detail',
                                s.variantRow
                            )}
                        >
                            <p className={s.size}>Size: {size}</p>
                        </div>
                        <div className={cn('mmds-component-one-detail')}>
                            <p className={s.colour}>Color: {colour}</p>
                        </div>
                        {discountMessage && (
                            <div
                                className={cn(
                                    s.discountMessage,
                                    'mmds-component-one-detail'
                                )}
                            >
                                {discountMessage}
                            </div>
                        )}
                    </div>
                    <div className="mmds-component-one-detail">
                        {!!compareAtPrice && (
                            <p className={s.comparePrice}>
                                <span>{compareAtPrice}</span>
                                <span>{currencyCode}</span>
                            </p>
                        )}
                        <p className={s.price}>
                            <span>{price}</span>
                            <span>{currencyCode}</span>
                        </p>
                    </div>
                </div>
                <div className={cn(s.meta)}>
                    {!bundleID && !giftID && !isUserInCreditShoppingMode && (
                        <div
                            className={cn(
                                s.quantity,
                                'mmds-component-one-detail-link'
                            )}
                        >
                            <select
                                className={s.quantitySelect}
                                value={quantity}
                                onClick={() => setIsSelectOpen(!isSelectOpen)}
                                onChange={(e) =>
                                    callbacks.updateQuantity(id, e.target.value)
                                }
                            >
                                {selectOptionsJSX}
                            </select>
                            <DownVector
                                className={cn(s.quantityVector, {
                                    [s.flip]: isSelectOpen,
                                })}
                            />
                        </div>
                    )}
                    <div className={cn(s.actions)}>
                        {!bundleID && !giftID && (
                            <Button
                                variant="link_alt"
                                onClick={() => {
                                    callbacks.addToWishlist(sku, handle);
                                    callbacks.removeFromCart(id);
                                }}
                                className={s.leftAlignedWishListButton}
                            >
                                <div className={s.wishlistDesktopAction}>
                                    Move to Wishlist
                                    <Bookmark />
                                </div>
                                <div className={s.wishlistMobileAction}>
                                    Wishlist
                                </div>
                            </Button>
                        )}
                        {!bundleID && !giftID && (
                            <Button
                                variant="link_alt"
                                onClick={() => callbacks.removeFromCart(id)}
                                className={cn(
                                    isUserInCreditShoppingMode &&
                                        s.leftAlignedRemoveButton
                                )}
                            >
                                Remove
                            </Button>
                        )}
                        {!!giftID && (
                            <Button
                                variant="link_alt"
                                onClick={() => callbacks.removeGift(giftID)}
                                className={s.leftAlignedRemoveButton}
                            >
                                Remove
                            </Button>
                        )}
                        {!!bundleID && (
                            <Button
                                variant="link_alt"
                                onClick={() => callbacks.removeBundle(bundleID)}
                                className={s.leftAlignedRemoveButton}
                            >
                                Remove Bundle
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    {Object.entries(errorMessages).map(([key, message]) => (
                        <p
                            key={key}
                            className={cn(
                                s.errorMessage,
                                'mmds-component-two-detail'
                            )}
                        >
                            {message}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
}
