'use client';

import React, { useState } from 'react';
import { DownVector } from '../../tokens/icons';
import cn from 'classnames';
import s from './select.module.css';

interface SelectProps {
    id?: string;
    className?: string;
    name: string;
    value?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    options: { value: string | number; label: string }[];
    label?: string;
    placeholder?: string;
    width?: string;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    (
        {
            id,
            className,
            value,
            name,
            onChange,
            options,
            label,
            placeholder,
            width = '38px',
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const handleToggleOpen = () => setIsOpen(!isOpen);
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (onChange) {
                onChange(e);
            }
            setIsOpen(false);
        };

        return (
            <div
                className={cn(
                    s.container,
                    className,
                    'mmds-component-one-detail-link'
                )}
            >
                {label && (
                    <label className="visually-hidden" htmlFor={id}>
                        {label}
                    </label>
                )}
                <select
                    ref={ref}
                    id={id}
                    name={name}
                    value={value}
                    className={cn(s.select, 'mmds-component-one')}
                    onClick={handleToggleOpen}
                    onChange={handleChange}
                    style={{ width }}
                >
                    {placeholder && (
                        <option
                            value=""
                            disabled
                            selected={value === '' || value === undefined}
                        >
                            {placeholder}
                        </option>
                    )}
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <DownVector
                    className={cn(s.vector, {
                        [s.flip]: isOpen,
                    })}
                />
            </div>
        );
    }
);

Select.displayName = 'Select';
export default Select;
