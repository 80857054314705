import React, { ComponentType, FC, ReactNode } from 'react';

export interface LinkManagerProps {
    children: ReactNode;
    href: string | null;
    title: string;
    externalTab?: boolean;
    className?: string;
    passthrough?: boolean;
    lang: string;
    target?: string;
}

export type LinkerComponent = ComponentType<LinkManagerProps>;

// TODO: Pass a link manager depending on your framework.
export const LinkManager: FC<LinkManagerProps> = ({
    children,
    href,
    target,
    title = 'Untitled Link',
    className,
    externalTab,
    passthrough,
}) => {
    if (passthrough || !href) return children;
    let targetType = externalTab ? '_blank' : '_self'
    if (target) {targetType = target}
    return (
        <a
            href={href}
            title={title || ''}
            data-event-title={title}
            className={className}
            target={targetType}
            rel="noreferrer"
        >
            {children}
        </a>
    );
};

export default LinkManager;
