import React from 'react';

const OrganicCotton = ({ props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clip-path="url(#clip0_5829_4089)">
                <path
                    d="M16 28C14.1739 28 12.6413 26.7283 12.25 25.0163C10.7989 25.8804 8.79348 25.6522 7.57065 24.4293C6.85326 23.712 6.44565 22.7337 6.44565 21.7065C6.44565 21.0054 6.625 20.337 6.9837 19.75C5.27174 19.3587 4 17.8261 4 16C4 14.1739 5.27174 12.6413 6.9837 12.25C6.6413 11.663 6.44565 10.9946 6.44565 10.2935C6.44565 9.2663 6.85326 8.30435 7.57065 7.57065C8.84239 6.29891 10.7663 6.10326 12.25 6.9837C12.6413 5.27174 14.1739 4 16 4C17.8261 4 19.3587 5.27174 19.75 6.9837C21.2174 6.10326 23.1576 6.31522 24.4293 7.57065C25.163 8.30435 25.5543 9.2663 25.5543 10.2935C25.5543 10.9946 25.375 11.663 25.0163 12.25C26.712 12.6413 28 14.1739 28 16C28 17.8261 26.7283 19.3587 25.0163 19.75C25.3587 20.337 25.5543 21.0054 25.5543 21.7065C25.5543 22.7337 25.1467 23.6957 24.4293 24.4293C23.2065 25.6522 21.2011 25.8804 19.75 25.0163C19.3587 26.7283 17.8261 28 16 28ZM16 10.0652C12.7228 10.0652 10.0652 12.7228 10.0652 16C10.0652 19.2772 12.7228 21.9348 16 21.9348C19.2772 21.9348 21.9348 19.2772 21.9348 16C21.9348 12.7228 19.2772 10.0652 16 10.0652Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_5829_4089">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(4 4)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default OrganicCotton;
