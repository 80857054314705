import React from 'react';

const FreeShipping = ({ ...props }) => {
    return (
        <svg
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.86632 12.6313H9.90738L11.2008 21.7365L11.2111 21.793H13.4181L14.7217 12.6313H14.7679V21.793H15.969V12H13.6388L12.3351 21.1617H12.2838L11.0006 12.0616L10.9904 12H8.66016V21.793H9.86632V12.6313Z"
                fill="#222222"
            />
            <path
                d="M23.2713 21.793H24.5647V19.976H26.0583V18.7699H24.5647V12.0103H22.2088L18.811 18.2823L18.8008 19.976H23.2713V21.793ZM23.2713 12.6313V18.7647H19.9248V18.6775L23.2097 12.621H23.2713V12.6313Z"
                fill="#222222"
            />
            <path
                d="M34.6611 12H32.3104L28.9126 18.272L28.9023 19.9658H33.3728V21.7827H34.6662V19.9658H36.1598V18.7596H34.6662V12H34.656H34.6611ZM30.0212 18.7596V18.6724L33.3061 12.621H33.3677V18.7545H30.0212V18.7648V18.7596Z"
                fill="#222222"
            />
            <path
                d="M40.7844 17.8152H43.0119C43.4995 17.8152 43.9461 17.7434 44.3207 17.5945C44.6954 17.4457 45.0085 17.2455 45.2651 16.9991C45.5166 16.7528 45.7117 16.4551 45.84 16.1266C45.9632 15.793 46.0299 15.4542 46.0299 15.095V14.6843C46.0299 14.3251 45.9683 13.9863 45.84 13.663C45.7065 13.3396 45.5269 13.0522 45.2703 12.8058C45.0239 12.5595 44.7005 12.3695 44.3259 12.2207C43.9512 12.0719 43.5047 12 43.0068 12H39.4961V21.793H40.7895V17.8101H40.7792L40.7844 17.8152ZM44.7416 14.7665V15.018C44.7416 16.0855 44.1308 16.6091 42.8733 16.6091H40.7844V13.2164H42.8733C43.5046 13.2164 43.982 13.3653 44.2797 13.6424C44.5876 13.9196 44.7416 14.3045 44.7416 14.7716V14.7665Z"
                fill="#222222"
            />
            <path
                d="M54.3751 14.0787L54.3238 14.0376L53.5693 14.787L53.5283 14.8281L54.7857 16.0855L55.4427 16.4551L55.4222 16.5167L54.8576 16.3422L48.9141 16.3319V17.4611H54.8371L55.4222 17.2866L55.4427 17.3379L54.8011 17.6972L53.5693 18.9187L53.5283 18.9701L54.2776 19.7194L54.3238 19.7656L56.4795 17.6099L56.5 17.5894V16.2087L54.3751 14.0787Z"
                fill="#222222"
            />
            <path
                d="M13.9268 29.148L13.1775 29.8973L13.1313 29.9384L14.3888 31.1959L15.0457 31.5757L15.0303 31.627L14.4606 31.4525H8.52734V32.5714H14.4452L15.0303 32.3969L15.0457 32.4482L14.4093 32.8075L13.1775 34.0393L13.1313 34.0804L13.8858 34.8298L13.9268 34.876L16.0825 32.7203L16.1133 32.6997V31.3191L13.9782 29.189L13.9268 29.148Z"
                fill="#222222"
            />
            <path
                d="M25.4991 28.3268V27.1206H19.4375V36.9034H20.7309V32.5971H25.4221V31.391H20.7309V28.3268H25.4991Z"
                fill="#222222"
            />
            <path
                d="M35.1804 27.9213C34.9289 27.6749 34.6107 27.485 34.236 27.3362C33.8613 27.1873 33.4148 27.1155 32.9169 27.1155H29.4062V36.9085H30.6997V32.9359H33.6406C33.8921 32.9359 34.0769 32.9974 34.1744 33.1001C34.2719 33.213 34.3233 33.3875 34.3233 33.6082V36.9033H35.6167V33.3567C35.6167 33.018 35.5294 32.7562 35.3652 32.5714C35.2061 32.3969 35.0008 32.284 34.7339 32.2327V32.1608C35.1445 31.9607 35.4524 31.6784 35.6424 31.3242C35.8425 30.9598 35.9503 30.58 35.9503 30.2156V29.805C35.9503 29.4457 35.8887 29.1069 35.7604 28.7836C35.6218 28.4602 35.4268 28.1728 35.1856 27.9264L35.1804 27.9213ZM34.6466 29.8871V30.1386C34.6466 30.6621 34.4978 31.0573 34.195 31.3242C33.8921 31.5911 33.4199 31.7246 32.7784 31.7246H30.6894V28.3268H32.7784C33.4097 28.3268 33.887 28.4756 34.1847 28.7528C34.4926 29.03 34.6466 29.4098 34.6466 29.882V29.8871Z"
                fill="#222222"
            />
            <path
                d="M45.2148 31.781C44.9531 31.5449 44.64 31.3704 44.291 31.237C43.942 31.1138 43.5724 31.0522 43.208 31.0522H42.7204V30.9906L45.8667 28.9735V27.1052H39.2816V28.3114H44.7478V28.373L41.6015 30.3901V32.1044H42.9616C43.4954 32.1044 43.9471 32.2635 44.2961 32.5766C44.6554 32.8897 44.8299 33.3208 44.8299 33.8854V33.9624C44.8299 34.2241 44.7786 34.4756 44.6708 34.7117C44.5681 34.9478 44.4193 35.1583 44.2345 35.3225C44.0497 35.497 43.8239 35.6305 43.5724 35.7331C43.3209 35.8306 43.0386 35.882 42.7512 35.882C42.4278 35.882 42.1199 35.8306 41.853 35.7331C41.5861 35.6356 41.3397 35.497 41.1344 35.3122C40.9343 35.1275 40.7752 34.9119 40.6725 34.6501C40.5596 34.3884 40.5083 34.0804 40.5083 33.7417V33.018H39.2148V33.8289C39.2148 34.2755 39.3021 34.7015 39.4612 35.1018C39.6255 35.5021 39.8616 35.8512 40.1798 36.1489C40.4877 36.4465 40.8675 36.6826 41.3089 36.8469C41.7503 37.0111 42.2431 37.0984 42.7923 37.0984C43.2645 37.0984 43.711 37.0214 44.1216 36.8623C44.5219 36.7032 44.8812 36.4979 45.1892 36.2258C45.4971 35.9538 45.723 35.6407 45.8975 35.2763C46.0617 34.9119 46.149 34.5167 46.149 34.0907V33.8392C46.149 33.3926 46.0617 32.9923 45.9026 32.6433C45.7127 32.3045 45.492 32.012 45.22 31.781H45.2148Z"
                fill="#222222"
            />
            <path
                d="M49.293 33.0231V33.834C49.293 34.2806 49.3802 34.7066 49.5393 35.1069C49.7036 35.5021 49.9397 35.8563 50.2476 36.154C50.5607 36.4517 50.9354 36.6878 51.3768 36.852C51.8182 37.0162 52.3109 37.1035 52.8601 37.1035C53.3323 37.1035 53.7789 37.0265 54.1895 36.8674C54.5898 36.7083 54.9491 36.503 55.257 36.231C55.565 35.9589 55.7908 35.6458 55.9653 35.2814C56.1296 34.917 56.2168 34.5218 56.2168 34.0958V33.8443C56.2168 33.3978 56.1296 32.9974 55.9705 32.6484C55.8062 32.3097 55.5855 32.012 55.3238 31.7759C55.062 31.5398 54.7489 31.3653 54.3999 31.2318C54.0509 31.1086 53.6813 31.047 53.3169 31.047H52.8293V30.9855L55.9756 28.9683V27.1001H49.3905V28.3062H54.8567V28.3678L51.7104 30.3849V32.1044H53.0706C53.6043 32.1044 54.056 32.2635 54.405 32.5766C54.7643 32.8896 54.9388 33.3208 54.9388 33.8854V33.9624C54.9388 34.2241 54.8875 34.4756 54.7797 34.7117C54.6771 34.9478 54.5282 35.1583 54.3434 35.3225C54.1587 35.497 53.9328 35.6305 53.6813 35.7331C53.4298 35.8306 53.1475 35.8819 52.8601 35.8819C52.5368 35.8819 52.2288 35.8306 51.9619 35.7331C51.695 35.6356 51.4487 35.497 51.2434 35.3122C51.0432 35.1377 50.8841 34.9119 50.7814 34.6501C50.6685 34.3884 50.6172 34.0804 50.6172 33.7417V33.018H49.2981L49.293 33.0231Z"
                fill="#222222"
            />
            <path
                d="M14.6386 46.2036C14.3666 45.9316 14.0381 45.716 13.6583 45.5672C13.2836 45.4183 12.8627 45.3413 12.411 45.3413C12.1031 45.3413 11.8157 45.3875 11.5642 45.4645C11.3127 45.5415 11.0766 45.6544 10.8815 45.7879C10.6814 45.9213 10.4966 46.0753 10.3477 46.2498C10.2092 46.4141 10.0962 46.5834 9.99872 46.7579H9.93713V43.4371H14.8439V42.231H8.67451V47.4611H10.9739L10.9842 47.4098C11.0098 47.3123 11.0612 47.2199 11.133 47.1121C11.2049 47.0094 11.2973 46.9119 11.4102 46.8349C11.5231 46.7579 11.6617 46.6861 11.8105 46.6348C11.9696 46.5834 12.1339 46.5578 12.3186 46.5578C12.5907 46.5578 12.8524 46.6091 13.0885 46.7169C13.3246 46.8247 13.5248 46.9632 13.6891 47.1377C13.8533 47.3123 13.9867 47.533 14.074 47.7691C14.1715 48.0052 14.2126 48.2669 14.2126 48.5441V48.6211C14.2126 48.9342 14.1664 49.2421 14.0791 49.5295C13.9919 49.817 13.8533 50.0787 13.6685 50.2994C13.4837 50.5201 13.2579 50.6946 12.9859 50.8229C12.7139 50.9513 12.4008 51.0129 12.0415 51.0129C11.3691 51.0129 10.8251 50.8281 10.4093 50.4534C9.99872 50.089 9.78828 49.5295 9.78828 48.7853V48.4209H8.5V48.8726C8.5 49.3448 8.57699 49.7913 8.72583 50.2019C8.88494 50.6125 9.11078 50.9718 9.40847 51.2695C9.70616 51.5672 10.0808 51.8033 10.5171 51.9778C10.9534 52.142 11.4666 52.2293 12.0261 52.2293C12.5855 52.2293 13.0834 52.1318 13.5197 51.9521C13.9457 51.7673 14.3152 51.5056 14.6027 51.1822C14.8901 50.8589 15.1159 50.4842 15.2648 50.0736C15.4136 49.6527 15.4906 49.2113 15.4906 48.7442V48.4979C15.4906 48.0359 15.4136 47.6048 15.2545 47.2147C15.121 46.8144 14.9055 46.4808 14.6334 46.2087L14.6386 46.2036Z"
                fill="#222222"
            />
            <path
                d="M19.4375 42.2258H20.6385V46.6501H24.8934V42.2258H26.0945V52.219H24.8934V47.7639H20.6385V52.219H19.4375V42.2258Z"
                fill="#222222"
            />
            <path
                d="M30.5604 48.1078V52.219H29.3594V42.2258H32.9265C33.4398 42.2258 33.8863 42.2977 34.2661 42.4465C34.6459 42.5954 34.9642 42.7904 35.2157 43.0368C35.4672 43.2832 35.6571 43.5706 35.7854 43.8991C35.9137 44.2276 35.9804 44.5714 35.9804 44.9359V45.3619C35.9804 45.7211 35.9188 46.0702 35.7854 46.4038C35.6571 46.7374 35.462 47.03 35.2054 47.2814C34.9488 47.5329 34.6305 47.7331 34.2507 47.882C33.8709 48.0308 33.4295 48.1027 32.9214 48.1027H30.5501L30.5604 48.1078ZM30.5604 46.994H32.7879C34.1224 46.994 34.7845 46.4243 34.7845 45.2797V45.0231C34.7845 44.5201 34.6203 44.1095 34.2918 43.8016C33.9633 43.4936 33.4603 43.3396 32.7879 43.3396H30.5604V46.994Z"
                fill="#222222"
            />
            <path
                d="M44.5041 51.7057H44.6735V42.2258H45.8745V52.219H43.4776L40.5931 42.7391H40.4237V52.219H39.2227V42.2258H41.6196L44.5041 51.7057Z"
                fill="#222222"
            />
            <path
                d="M51.8399 47.42H56.1205V52.0342H55.0477V50.8794H54.8835C54.7295 51.2797 54.4524 51.6082 54.0623 51.8546C53.6671 52.101 53.2103 52.2241 52.6919 52.2241C51.6551 52.2241 50.8441 51.8905 50.2539 51.2233C49.6637 50.5561 49.3711 49.5809 49.3711 48.2977V46.1574C49.3711 44.9153 49.6688 43.9453 50.2642 43.2626C50.8595 42.5749 51.6859 42.231 52.7483 42.231C53.2975 42.231 53.78 42.3182 54.2009 42.4979C54.6217 42.6775 54.9759 42.9239 55.2582 43.237C55.5405 43.5501 55.756 43.9247 55.9049 44.3507C56.0486 44.7768 56.1256 45.2336 56.1256 45.716V45.8803H54.9708V45.716C54.9708 45.4029 54.9297 45.1052 54.8424 44.8075C54.7552 44.5099 54.6217 44.2584 54.4421 44.0325C54.2625 43.8067 54.0366 43.6322 53.7543 43.4987C53.4772 43.3653 53.1384 43.2986 52.7432 43.2986C52.0041 43.2986 51.4447 43.5706 51.0751 44.1095C50.7056 44.6484 50.5208 45.3567 50.5208 46.2344V48.2105C50.5208 49.1549 50.7056 49.8785 51.0751 50.3867C51.4447 50.8948 52.0092 51.1463 52.7689 51.1463C53.4566 51.1463 53.9904 50.9256 54.3805 50.4893C54.7706 50.0479 54.9656 49.4371 54.9656 48.6519V48.4876H51.8347V47.4149L51.8399 47.42Z"
                fill="#222222"
            />
        </svg>
    );
};

export default FreeShipping;
