import React from 'react';

const FlagEU = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_4602_678)">
                <path d="M16.01 0H0.0100098V12H16.01V0Z" fill="#2E42A5" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.36001 4.92L4.66001 5.51L5.31001 5.6L4.84001 6.06L4.95001 6.71L4.36001 6.41L3.78001 6.71L3.89001 6.06L3.42001 5.6L4.07001 5.51L4.36001 4.92Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.66 4.92L11.95 5.51L12.6 5.6L12.13 6.06L12.24 6.71L11.66 6.41L11.07 6.71L11.18 6.06L10.71 5.6L11.36 5.51L11.66 4.92Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.92999 8.61L8.21999 9.2L8.87999 9.29L8.39999 9.76L8.51999 10.41L7.92999 10.1L7.34999 10.41L7.45999 9.76L6.98999 9.29L7.63999 9.2L7.92999 8.61Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.92999 1.22L8.21999 1.82L8.87999 1.91L8.39999 2.37L8.51999 3.02L7.92999 2.71L7.34999 3.02L7.45999 2.37L6.98999 1.91L7.63999 1.82L7.92999 1.22Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70001 1.89L10 2.48L10.65 2.58L10.18 3.04L10.29 3.69L9.70001 3.38L9.12001 3.69L9.23001 3.04L8.76001 2.58L9.41001 2.48L9.70001 1.89Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.2 1.89L6.49 2.48L7.14 2.58L6.67 3.04L6.78 3.69L6.2 3.38L5.61 3.69L5.72 3.04L5.25 2.58L5.91 2.48L6.2 1.89Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70001 8.07L10 8.66L10.65 8.76L10.18 9.22L10.29 9.87L9.70001 9.56L9.12001 9.87L9.23001 9.22L8.76001 8.76L9.41001 8.66L9.70001 8.07Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.2 8.07L6.49 8.66L7.14 8.76L6.67 9.22L6.78 9.87L6.2 9.56L5.61 9.87L5.72 9.22L5.25 8.76L5.91 8.66L6.2 8.07Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.24 3.16L11.53 3.75L12.18 3.85L11.71 4.31L11.82 4.96L11.24 4.65L10.65 4.96L10.76 4.31L10.29 3.85L10.94 3.75L11.24 3.16Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.66 3.16L4.96 3.75L5.61 3.85L5.14 4.31L5.25 4.96L4.66 4.65L4.08 4.96L4.19 4.31L3.72 3.85L4.37 3.75L4.66 3.16Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.24 6.78L11.53 7.37L12.18 7.47L11.71 7.93L11.82 8.58L11.24 8.27L10.65 8.58L10.76 7.93L10.29 7.47L10.94 7.37L11.24 6.78Z"
                    fill="#F7FC00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.66 6.78L4.96 7.37L5.61 7.47L5.14 7.93L5.25 8.58L4.66 8.27L4.08 8.58L4.19 7.93L3.72 7.47L4.37 7.37L4.66 6.78Z"
                    fill="#F7FC00"
                />
            </g>
            <defs>
                <clipPath id="clip0_4602_678">
                    <rect width="16.01" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagEU;
