import React from 'react';

const FlagSG = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M0 0H16V12H0V0Z" fill="white" />
            <path d="M0 0H16V6H0V0Z" fill="#ED2939" />
            <path
                d="M3.80166 5.20832C5.02128 5.20832 6.00999 4.21962 6.00999 2.99999C6.00999 1.78036 5.02128 0.791656 3.80166 0.791656C2.58203 0.791656 1.59332 1.78036 1.59332 2.99999C1.59332 4.21962 2.58203 5.20832 3.80166 5.20832Z"
                fill="white"
            />
            <path
                d="M4.80166 5.20832C6.02128 5.20832 7.00999 4.21962 7.00999 2.99999C7.00999 1.78036 6.02128 0.791656 4.80166 0.791656C3.58203 0.791656 2.59332 1.78036 2.59332 2.99999C2.59332 4.21962 3.58203 5.20832 4.80166 5.20832Z"
                fill="#ED2939"
            />
            <path
                d="M5.00999 1.15833L4.67202 2.19851L5.55685 1.55564H4.46313L5.34797 2.19851L5.00999 1.15833Z"
                fill="white"
            />
            <path
                d="M6.76155 2.43089L5.66783 2.43089L6.55267 3.07376L6.21469 2.03357L5.87671 3.07376L6.76155 2.43089Z"
                fill="white"
            />
            <path
                d="M3.92752 4.48994L4.81235 3.84707L3.71864 3.84707L4.60347 4.48994L4.26549 3.44975L3.92752 4.48994Z"
                fill="white"
            />
            <path
                d="M6.09267 4.48977L5.75423 3.44983L5.41676 4.49008L6.30124 3.84685L5.20759 3.84737L6.09267 4.48977Z"
                fill="white"
            />
            <path
                d="M3.25815 2.43121L4.14381 3.07366L3.80494 2.03336L3.46759 3.07415L4.3523 2.43042L3.25815 2.43121Z"
                fill="white"
            />
        </svg>
    );
};

export default FlagSG;
