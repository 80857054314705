import React from 'react';

const FourWayStretch = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2547 3H29L28.9942 8.72H26.8455L26.8513 6.55727L17.521 15.8405L26.828 25.1177L26.8338 23.2741H28.9766L28.9708 28.9941H23.2255L23.2313 26.855H25.5318L15.9971 17.3532L6.45071 26.8609L8.74534 26.855V29H3L3.00584 23.2741H5.1545V25.1236L14.479 15.8405L5.17202 6.55727V8.72591H3.02336L3.02919 3H8.77453L8.7687 5.145H6.79519L16.0029 14.3277L25.234 5.13909H23.2547V3ZM21.4388 5.71818L19.9207 4.20545L16.0088 8.09955L12.1026 4.20545L10.5846 5.72409L16.0029 11.1309L21.4388 5.71818ZM10.5612 26.2759L12.0793 27.7945L15.9912 23.8945L19.8974 27.7886L21.4154 26.2759L15.9971 20.8691L10.5612 26.2759ZM20.89 16L26.3258 10.5873L27.8439 12.1059L23.932 15.9941L27.8381 19.8882L26.3142 21.4068L20.89 16ZM5.67415 21.4068L11.11 16L5.68583 10.5932L4.16775 12.1059L8.06804 16L4.15607 19.8941L5.67415 21.4068Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FourWayStretch;
