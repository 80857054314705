'use client';

import cn from 'clsx';
import React, { Suspense } from 'react';
import { BookmarkFilled, BookmarkUnfilled } from '../../tokens/icons';
import { convertPriceToNumber } from '../../utilities/convert-price-to-number';
import LinkManager, { LinkerComponent } from '../link-manager/LinkManager';
import ProductVariants from './ProductVariants';
import gtmTracker from './trackers/gtm';
import nostoTracker from './trackers/nosto';
import {
    NostoProductCardAttributes,
    ProductCardProduct,
    ProductCardSliceVariant,
} from './types';
import s from './ProductCard.module.css';

const VARIANT_STANDARD = 'standard';
const VARIANT_ALTERNATIVE = 'alternative';

export type PhotoVariant = 'flatlay' | 'model';

export type ProductCardProps = {
    metafields?: any;
    node?: any;
    variant?: ProductCardSliceVariant;
    product: ProductCardProduct;
    label?: string | null;
    coloursCount?: number;
    backgroundColour?: string;
    photoVariant?: PhotoVariant;
    nostoProductCardAttributes?: NostoProductCardAttributes;
    position?: number | null;
    currencyCode?: string;
    saved: boolean;
    toggleSaved?: (variantSKU: string, handle: string) => void;
    lang: string;
    Linker?: LinkerComponent;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
    hovered?: boolean;
};

export const ProductCard = ({
    variant = VARIANT_STANDARD,
    product,
    label,
    coloursCount,
    backgroundColour,
    photoVariant = 'flatlay',
    nostoProductCardAttributes,
    position = null,
    saved = false,
    toggleSaved,
    lang,
    Linker = LinkManager,
    onMouseEnter,
    onMouseLeave,
    onClick,
    hovered,
}: ProductCardProps) => {
    const {
        id,
        sku,
        handle,
        title,
        price,
        compareAtPrice,
        currencyCode,
        image,
        hoverImage,
        video,
        variants,
    } = product;

    const WishlistVector = saved ? BookmarkFilled : BookmarkUnfilled;
    const rootClassnames = cn(s.root, {
        [s.alternative]: variant === VARIANT_ALTERNATIVE,
    });
    const imageContainerProps = {
        style: {
            background: backgroundColour || 'auto',
        },
    };

    // Ensuring all the price types are numbers because they come from different sources such as Shopify and Nosto
    // and the upstream type is not guaranteed by the time we reach this code.
    let priceAsNumber =
        typeof price !== 'number' ? convertPriceToNumber(price) : price;
    let compareAtPriceAsNumber =
        typeof compareAtPrice !== 'number'
            ? convertPriceToNumber(compareAtPrice)
            : compareAtPrice;

    const showCompareAtPrice =
        compareAtPrice && compareAtPriceAsNumber > priceAsNumber;

    const eventDetails = {
        id,
        sku,
        productTitle: title,
        variantTitle: title,
        category: 'Apparel',
        priceAsNumber,
        currency: currencyCode,
        position,
        handle,
    };

    const handleOnClick = () => {
        gtmTracker(eventDetails);
        if (id && nostoProductCardAttributes?.isNostoProductCard === true) {
            nostoTracker(id, nostoProductCardAttributes.result_id);
        }
        if (onClick) onClick();
    };

    let activeImage = typeof image !== 'undefined' ? image : null;
    if (hovered) {
        activeImage = hoverImage || activeImage;
    }
    if (photoVariant === 'model') {
        activeImage = hoverImage || activeImage;
    }

    const priceFormatter = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
    });

    let coloursCountText = '1 Colour';
    if (coloursCount && coloursCount > 1) {
        coloursCountText = `${coloursCount} Colours`;
    }

    const ACTIVE_IMAGE_TYPE = activeImage?.includes('.mp4') ? 'video' : 'image';
    const showVideo = !!video?.url && hovered;
    return (
        <Suspense fallback={<div>Loading product card...</div>}>
            <div
                className={rootClassnames}
                data-testid={`PC-SV-${handle}`}
                data-handle={handle}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            >
                {!!toggleSaved && (
                    <button
                        className={s.wishlist}
                        onClick={(e) => {
                            e.preventDefault();
                            if (toggleSaved) toggleSaved(sku, handle);
                        }}
                    >
                        <WishlistVector />
                    </button>
                )}
                <Linker
                    passthrough={!handle}
                    href={`/products/${handle}`}
                    title={!showVideo ? `Go to product ${title}` : ''}
                    lang={lang}
                >
                    <div className={cn(s.image)} {...imageContainerProps}>
                        {!!activeImage && (
                            <img
                                src={`${showVideo ? video?.poster : activeImage}&width=1000&height=1333&crop=top`}
                                alt={`Product Image for ${title}`}
                                width="640"
                                height="900"
                                loading="lazy"
                            />
                        )}
                        {showVideo && (
                            <video
                                autoPlay
                                muted
                                playsInline
                                loop
                                width="640"
                                height="900"
                                className={s.video}
                                poster={`${video?.poster}&width=1000&height=1333&crop=top`}
                            >
                                <source
                                    width="640"
                                    height="900"
                                    src={video?.url}
                                    type="video/mp4"
                                />
                            </video>
                        )}
                        {!activeImage && (
                            <div
                                className={cn(
                                    s.imagePlaceholder,
                                    'mmds-component-one-detail'
                                )}
                            >
                                Image Missing
                            </div>
                        )}
                        {coloursCount && hovered ? (
                            <div className={s.coloursCount}>
                                {coloursCountText}
                            </div>
                        ) : null}
                        {label && <div className={s.label}>{label}</div>}
                    </div>
                    <div className={s.summary}>
                        {hovered && <ProductVariants variants={variants} />}
                        {!hovered && <div className={s.title}>{title}</div>}
                        {!!price && (
                            <div className={s.pricing}>
                                {showCompareAtPrice && (
                                    <div className={s.compareAtPrice}>
                                        {priceFormatter.format(
                                            compareAtPriceAsNumber
                                        )}{' '}
                                        {currencyCode}
                                    </div>
                                )}
                                <div className={s.price}>
                                    {priceFormatter.format(priceAsNumber)}{' '}
                                    {currencyCode}
                                </div>
                            </div>
                        )}
                    </div>
                </Linker>
            </div>
        </Suspense>
    );
};

export default ProductCard;
