import React from 'react';

const Packable = ({ props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.997 2C23.7318 2 30 4.90635 30 8.49304H29.9939C29.9939 10.8908 27.1884 12.9847 23.0198 14.1076C24.2126 14.8077 24.9246 15.6929 24.9246 16.6572C24.9246 18.2425 23.0198 19.6098 20.2143 20.31C21.1393 20.779 21.7053 21.3999 21.7053 22.0868C21.7053 23.5532 19.1493 24.7355 15.997 24.7355C12.8446 24.7355 10.2886 23.5466 10.2886 22.0868C10.2886 21.4065 10.8546 20.779 11.7796 20.31C8.98022 19.6098 7.06933 18.2359 7.06933 16.6572C7.06933 15.6929 7.78744 14.8077 8.97414 14.1076C4.80548 12.9847 2 10.8908 2 8.49304C2 4.90635 8.26212 2 15.997 2ZM20.0926 28.1043C20.0926 29.1513 18.2616 30 16.003 30C13.7444 30 11.9135 29.1513 11.9135 28.1043C11.9135 27.0573 13.7444 26.2085 16.003 26.2085C18.2616 26.2085 20.0926 27.0573 20.0926 28.1043Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Packable;
