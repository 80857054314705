import React from 'react';

const Search = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.6 11.6L11.4 11.78C11.53 11.66 11.65 11.54 11.77 11.41L11.6 11.6L15.29 15.29L11.6 11.6ZM13.49 7.01001C13.49 3.43001 10.57 0.51001 6.98999 0.51001C3.40999 0.51001 0.48999 3.43001 0.48999 7.01001C0.48999 3.43001 3.40999 0.51001 6.98999 0.51001C10.57 0.51001 13.49 3.43001 13.49 7.01001Z"
                fill="currentColor"
            />
            <path
                d="M7 0C3.13 0 0 3.13 0 7C0 10.87 3.13 14 7 14C8.75 14 10.35 13.35 11.58 12.29L15.29 16L16 15.29L12.29 11.58C13.35 10.35 14 8.75 14 7C14 3.13 10.87 0 7 0ZM7 13C3.69 13 1 10.31 1 7C1 3.69 3.69 1 7 1C10.31 1 13 3.69 13 7C13 10.31 10.31 13 7 13Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Search;
