const DownVector = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Vector">
                <path
                    id="Vector_2"
                    d="M7.5 11.3333L3.60289 6.83333L11.3971 6.83333L7.5 11.3333Z"
                    fill="#222222"
                />
            </g>
        </svg>
    );
};

export default DownVector;
