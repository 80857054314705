import React from 'react';

const MAAPHoneycomb = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.3768 9.86446L22.2512 11.4864V14.7449L19.362 16.3741L16.5025 14.7618V11.4864L19.3768 9.86446ZM15.9938 22.1597L13.1121 20.5329V17.2189L15.9864 15.5969L18.8607 17.2189V20.5401L15.9938 22.1573V22.1597ZM9.24014 15.5993L12.1145 17.2213V20.5425L9.24755 22.1597L6.36582 20.5329V17.2189L9.24014 15.5969V15.5993ZM9.74636 26.2484V23.0045L12.6207 21.3825L15.495 23.0045V26.2484L12.6207 27.8704L9.74636 26.2484ZM28.99 26.2484L26.1157 27.8704L23.2414 26.2484V23.0045L26.1157 21.3825L28.99 23.0045V26.2484ZM28.9974 14.7183L26.0861 16.362L23.2488 14.7618V11.4864L26.1231 9.86446L28.9974 11.4864V14.7208V14.7183ZM25.6095 5.74916V9.02451L22.75 10.6368L19.8608 9.00761V5.74916L22.7352 4.12718L25.6095 5.74916ZM8.7438 26.8374L12.6207 29L16.4951 26.7818V23.0045L19.3694 21.3825L22.2438 23.0045V26.8446L26.1182 28.9976L29.9926 26.8108V22.389L26.612 20.4894V17.1948L30 15.2856V10.9192L26.612 9.00761V5.74916L29.4864 4.12718V3L26.1132 4.90438L22.7401 3L18.8657 5.24229V9.02451L16.0062 10.6368L13.117 9.00761V5.25195L9.24014 3L5.36573 5.18678V9.02692L2.00741 10.9216V15.0104L3.0075 15.7393V11.484L5.88182 9.86205L8.75615 11.484V14.7425L5.3682 16.6541V20.5401L2 22.4397V26.8108L5.87442 28.9976V27.8704L3.00009 26.2484V23.0045L5.87442 21.3825L8.74627 23.0045V26.8349L8.7438 26.8374ZM6.36582 9.00761V5.74916L9.24014 4.12718L12.1145 5.74916V9.02451L9.25496 10.6368L6.36582 9.00761Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default MAAPHoneycomb;
