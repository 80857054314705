import React from 'react';

const TouchscreenConductive = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 2C8.28302 2 2 8.28302 2 16C2 23.717 8.28302 30 16 30C23.717 30 30 23.717 30 16C30 8.28302 23.717 2 16 2ZM16 28.7421C8.97484 28.7421 3.25786 23.0252 3.25786 16C3.25786 8.97484 8.97484 3.25786 16 3.25786C23.0252 3.25786 28.7421 8.97484 28.7421 16C28.7421 23.0252 23.0252 28.7421 16 28.7421ZM4.67297 16C4.67297 9.75473 9.75473 4.67297 16 4.67297C22.2453 4.67297 27.3271 9.75473 27.3271 16C27.3271 22.2453 22.2453 27.3271 16 27.3271C9.75473 27.3271 4.67297 22.2453 4.67297 16ZM5.93084 16C5.93084 21.5535 10.4466 26.0692 16 26.0692C21.5535 26.0692 26.0692 21.5535 26.0692 16C26.0692 10.4466 21.5535 5.93084 16 5.93084C10.4466 5.93084 5.93084 10.4466 5.93084 16ZM16 7.34589C11.2327 7.34589 7.34595 11.2264 7.34595 16C7.34595 20.7736 11.2264 24.6541 16 24.6541C20.7736 24.6541 24.6541 20.7736 24.6541 16C24.6541 11.2264 20.7736 7.34589 16 7.34589ZM16 23.3899C11.9246 23.3899 8.60381 20.0754 8.60381 15.9937C8.60381 11.9119 11.9183 8.59746 16 8.59746C20.0818 8.59746 23.3963 11.9119 23.3963 15.9937C23.3963 20.0754 20.0818 23.3899 16 23.3899ZM10.0251 16C10.0251 12.7044 12.7044 10.0251 16 10.0251C19.2956 10.0251 21.9748 12.7044 21.9748 16C21.9748 19.2956 19.2956 21.9748 16 21.9748C12.7044 21.9748 10.0251 19.2956 10.0251 16ZM11.283 16.0063C11.283 18.6038 13.3962 20.7233 16 20.7233C18.5975 20.7233 20.717 18.6038 20.717 16.0063C20.717 13.4088 18.5975 11.2893 16 11.2893C13.4025 11.2893 11.283 13.4088 11.283 16.0063ZM12.6981 16C12.6981 14.1824 14.1761 12.6981 16 12.6981C17.8176 12.6981 19.3019 14.1824 19.3019 16C19.3019 17.8176 17.8176 19.3019 16 19.3019C14.1824 19.3019 12.6981 17.8176 12.6981 16ZM13.956 16C13.956 17.1258 14.8742 18.044 16 18.044C17.1258 18.044 18.044 17.1258 18.044 16C18.044 14.8742 17.1258 13.956 16 13.956C14.8742 13.956 13.956 14.8742 13.956 16ZM15.1069 16C15.1069 15.5032 15.5094 15.1069 16 15.1069C16.4969 15.1069 16.8931 15.5032 16.8931 16C16.8931 16.4969 16.4969 16.8931 16 16.8931C15.5032 16.8931 15.1069 16.4969 15.1069 16Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TouchscreenConductive;
