import React from 'react';

const Globe = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.596 0.16C9.08568 0.06 8.56535 0 8.02502 0H7.995C7.45466 0 6.93433 0.06 6.42402 0.16C2.75172 0.9 0 4.13 0 8C0 11.87 2.75172 15.1 6.41401 15.84C6.92433 15.94 7.44465 16 7.98499 16H8.01501C8.55535 16 9.07567 15.94 9.58599 15.84C13.2483 15.1 16 11.87 16 8C16 4.13 13.2583 0.9 9.596 0.16ZM14.329 5H11.5372C11.0769 3.53 10.2764 2.21 9.24578 1.12C11.4972 1.52 13.3784 3 14.329 5ZM8.005 1.31C9.1157 2.31 9.97623 3.57 10.4866 5H5.52345C6.03377 3.57 6.89431 2.3 8.005 1.31ZM11.0069 8.01C11.0069 8.7 10.9268 9.37 10.7767 10.01H5.23327C5.08318 9.37 5.00313 8.7 5.00313 8.01C5.00313 7.32 5.08318 6.65 5.23327 6.01H10.7767C10.9268 6.65 11.0069 7.32 11.0069 8.01ZM6.76423 1.12C5.72358 2.21 4.93308 3.53 4.4728 5H1.67104C2.62164 3 4.50281 1.52 6.75422 1.12H6.76423ZM1.30081 6H4.21263C4.08255 6.65 4.01251 7.31 4.01251 8C4.01251 8.69 4.08255 9.35 4.21263 10H1.30081C1.11069 9.37 1.00063 8.7 1.00063 8C1.00063 7.3 1.11069 6.63 1.30081 6ZM1.68105 11H4.4728C4.93308 12.47 5.73358 13.79 6.76423 14.88C4.51282 14.48 2.63164 13 1.68105 11ZM8.01501 14.69C6.90432 13.69 6.04378 12.43 5.53346 11H10.4866C9.97623 12.43 9.1157 13.7 8.005 14.69H8.01501ZM9.25578 14.88C10.2964 13.79 11.0869 12.47 11.5472 11H14.339C13.3884 13 11.5072 14.48 9.25578 14.88ZM14.7192 10H11.8074C11.9375 9.35 12.0075 8.69 12.0075 8C12.0075 7.31 11.9375 6.65 11.8074 6H14.7192C14.9093 6.63 15.0194 7.3 15.0194 8C15.0194 8.7 14.9093 9.37 14.7192 10Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Globe;
