const FlagTW = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clip-path="url(#clip0_7508_21995)">
                <rect width="16" height="12" fill="white" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 -1V14H20V-1H0Z"
                    fill="#EF0000"
                />
                <mask
                    id="mask0_7508_21995"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="-1"
                    width="20"
                    height="15"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 -1V14H20V-1H0Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_7508_21995)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 0V8H9V0H0Z"
                        fill="#2E42A5"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.5 5.16427L3.83013 6L3.66786 4.9413L2.66987 5.33013L3.0587 4.33213L2 4.16987L2.83573 3.5L2 2.83013L3.0587 2.66786L2.66987 1.66987L3.66786 2.0587L3.83013 1L4.5 1.83573L5.16987 1L5.33213 2.0587L6.33013 1.66987L5.9413 2.66786L7 2.83013L6.16427 3.5L7 4.16987L5.9413 4.33213L6.33013 5.33013L5.33213 4.9413L5.16987 6L4.5 5.16427ZM4.5 4.7941C5.21471 4.7941 5.7941 4.21471 5.7941 3.5C5.7941 2.78529 5.21471 2.20591 4.5 2.20591C3.78529 2.20591 3.20591 2.78529 3.20591 3.5C3.20591 4.21471 3.78529 4.7941 4.5 4.7941ZM5.53527 3.5C5.53527 4.07176 5.07176 4.53527 4.5 4.53527C3.92823 4.53527 3.46472 4.07176 3.46472 3.5C3.46472 2.92823 3.92823 2.46472 4.5 2.46472C5.07176 2.46472 5.53527 2.92823 5.53527 3.5Z"
                        fill="#FEFFFF"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_7508_21995">
                    <rect width="16" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagTW;
