export type GTMClickProductCardEventProps = {
    id?: string;
    handle: string;
    productTitle: string;
    variantTitle: string;
    price?: number;
    currency: string;
    sku: string;
    category?: string;
    position?: number | null;
};

export default ({
    id,
    handle,
    productTitle,
    variantTitle,
    price,
    currency,
    sku,
    category,
    position,
}: GTMClickProductCardEventProps) => {
    (window as any)?.dataLayer?.push({
        event: 'gtm.clickProductImpression',
        ecommerce: {
            currencyCode: currency,
            click: {
                products: [
                    {
                        id: id,
                        name: productTitle,
                        price,
                        brand: 'MAAP',
                        category,
                        variant: variantTitle,
                        list: category,
                        position,
                        item_id: handle,
                        item_variant: sku,
                    },
                ],
            },
        },
    });
};
