import React from 'react';

const FlagAU = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>Australian Flag</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0H16V12H0V0Z"
                fill="#2E42A5"
            />
            <g clipPath="url(#clip0_4253_7644)">
                <rect width="9" height="7" fill="#2E42A5" />
                <path
                    d="M-1.00214 6.50005L0.978339 7.36865L9.04494 0.944513L10.0896 -0.346252L7.97176 -0.636564L4.68159 2.13178L2.03334 3.997L-1.00214 6.50005Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M-0.731018 7.10824L0.277943 7.6123L9.71445 -0.466553H8.29775L-0.731018 7.10824Z"
                    fill="#F50100"
                />
                <path
                    d="M10.0021 6.50005L8.02166 7.36865L-0.0449371 0.944513L-1.08959 -0.346252L1.02824 -0.636564L4.31841 2.13178L6.96666 3.997L10.0021 6.50005Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M9.93457 6.93685L8.92561 7.44092L4.90745 3.98194L3.71615 3.59548L-1.1901 -0.34179H0.226597L5.13009 3.50202L6.43256 3.96543L9.93457 6.93685Z"
                    fill="#F50100"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
                    fill="#F50100"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.24226 -0.75H5.74226V2.25H9.75V4.75H5.74226V7.75H3.24226V4.75H-0.75V2.25H3.24226V-0.75ZM3.99226 3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0H3.99226V3Z"
                    fill="#F7FCFF"
                />
            </g>
            <g clipPath="url(#clip1_4253_7644)">
                <path
                    d="M4.40798 9.83417L3.81755 10.3798L3.87602 9.57799L3.0813 9.45658L3.74464 9.00237L3.34406 8.30532L4.11277 8.54075L4.40798 7.79297L4.70319 8.54075L5.4719 8.30532L5.07132 9.00237L5.73466 9.45658L4.93994 9.57799L4.99841 10.3798L4.40798 9.83417Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M10.776 6.06881L10.3824 6.43257L10.4213 5.89802L9.89152 5.81708L10.3337 5.51427L10.0667 5.04958L10.5792 5.20653L10.776 4.70801L10.9728 5.20653L11.4853 5.04958L11.2182 5.51427L11.6604 5.81708L11.1306 5.89802L11.1696 6.43257L10.776 6.06881Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M11.7147 2.37691L11.3211 2.74067L11.3601 2.20613L10.8302 2.12518L11.2725 1.82238L11.0054 1.35768L11.5179 1.51463L11.7147 1.01611L11.9115 1.51463L12.424 1.35768L12.1569 1.82238L12.5991 2.12518L12.0693 2.20613L12.1083 2.74067L11.7147 2.37691Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M14.0613 4.22262L13.6677 4.58638L13.7067 4.05183L13.1769 3.97089L13.6191 3.66808L13.352 3.20339L13.8645 3.36034L14.0613 2.86182L14.2581 3.36034L14.7706 3.20339L14.5035 3.66808L14.9458 3.97089L14.416 4.05183L14.4549 4.58638L14.0613 4.22262Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M12.184 9.53072L11.7904 9.89448L11.8294 9.35994L11.2995 9.27899L11.7418 8.97619L11.4747 8.51149L11.9872 8.66844L12.184 8.16992L12.3808 8.66844L12.8933 8.51149L12.6262 8.97619L13.0684 9.27899L12.5386 9.35994L12.5776 9.89448L12.184 9.53072Z"
                    fill="#F7FCFF"
                />
                <path
                    d="M13.8266 6.64804L13.4267 6.8583L13.5031 6.41297L13.1795 6.09759L13.6267 6.03261L13.8266 5.62744L14.0266 6.03261L14.4737 6.09759L14.1502 6.41297L14.2266 6.8583L13.8266 6.64804Z"
                    fill="#F7FCFF"
                />
            </g>
            <defs>
                <clipPath id="clip0_4253_7644">
                    <rect width="9" height="7" fill="white" />
                </clipPath>
                <clipPath id="clip1_4253_7644">
                    <rect
                        width="12"
                        height="10"
                        fill="white"
                        transform="translate(3 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagAU;
