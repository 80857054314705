'use client';

import { useState } from 'react';
import s from './expandable-text.module.css';
import cn from 'clsx';

export interface ExpandableExpandAreaProps {
    text: string;
}

function ExpandableTextExpandedArea({ text }: ExpandableExpandAreaProps) {
    const [expanded, setExpanded] = useState<boolean>(false);
    return (
        <>
            {!expanded && '...'}{' '}
            {expanded && (
                <span
                    style={{ whiteSpace: 'pre-line' }}
                    className={cn(s.fullText, 'mmds-copy-one')}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
            <p>
                <button
                    className={cn(s.expandButton, 'mmds-component-one-detail')}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? '< Read less' : 'Read more >'}
                </button>
            </p>
        </>
    );
}

export default ExpandableTextExpandedArea;
