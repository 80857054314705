import React from 'react';

const FlagUK = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="12"
            viewBox="0 0 17 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask
                id="mask0_4601_2172"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="17"
                height="12"
            >
                <path d="M16.12 0H0.119995V12H16.12V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_4601_2172)">
                <path d="M16.12 0H0.119995V12H16.12V0Z" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.119995 0V12H16.12V0H0.119995Z"
                    fill="#2E42A5"
                />
                <path
                    d="M-1.67001 11.14L1.84999 12.63L16.2 1.62L18.06 -0.59L14.3 -1.09L8.44999 3.66L3.73999 6.86L-1.67001 11.14Z"
                    fill="white"
                />
                <path
                    d="M-1.17999 12.19L0.610007 13.05L17.39 -0.799999H14.87L-1.17999 12.19Z"
                    fill="#F50100"
                />
                <path
                    d="M17.9 11.14L14.38 12.63L0.0399926 1.62L-1.82001 -0.59L1.94999 -1.09L7.79999 3.66L12.51 6.86L17.91 11.15L17.9 11.14Z"
                    fill="white"
                />
                <path
                    d="M17.78 11.89L15.99 12.75L8.85 6.82L6.73 6.16L-2 -0.59H0.52L9.24 6L11.56 6.79L17.79 11.88L17.78 11.89Z"
                    fill="#F50100"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 -1H6.22001V4H-0.869995V8H6.23V13H10.01V8H17.13V4H10.01V-1H10Z"
                    fill="#F50100"
                />
                <path
                    d="M6.23 -1V-2H5.23V-1H6.23ZM10.01 -1H11.01V-2H10.01V-1ZM6.23 4V5H7.23V4H6.23ZM-0.869995 4V3H-1.87V4H-0.869995ZM-0.869995 8H-1.87V9H-0.869995V8ZM6.23 8H7.23V7H6.23V8ZM6.23 13H5.23V14H6.23V13ZM10.01 13V14H11.01V13H10.01ZM10.01 8V7H9.01V8H10.01ZM17.13 8V9H18.13V8H17.13ZM17.13 4H18.13V3H17.13V4ZM10.01 4H9.01V5H10.01V4ZM6.23 0H10.01V-2H6.23V0ZM7.23 4V-1H5.23V4H7.23ZM-0.869995 5H6.23V3H-0.869995V5ZM0.130005 8V4H-1.87V8H0.130005ZM6.23 7H-0.869995V9H6.23V7ZM7.23 13V8H5.23V13H7.23ZM10.01 12H6.23V14H10.01V12ZM9.01 8V13H11.01V8H9.01ZM17.13 7H10.01V9H17.13V7ZM16.13 4V8H18.13V4H16.13ZM10.01 5H17.13V3H10.01V5ZM9.01 -1V4H11.01V-1H9.01Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};

export default FlagUK;
