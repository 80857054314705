'use client';

import React, { ReactNode, forwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Icon } from '../../tokens/icons';
import cn from 'clsx';
import s from './Checkbox.module.css';

type CheckboxBaseProps = Pick<
    CheckboxPrimitive.CheckboxProps,
    | 'checked'
    | 'defaultChecked'
    | 'required'
    | 'disabled'
    | 'value'
    | 'onCheckedChange'
>;
export interface CheckboxProps extends CheckboxBaseProps {
    label: string | ReactNode;
    id: string;
    position?: string;
}

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
    (
        {
            label,
            id,
            position = 'center',
            onCheckedChange,
            checked,
            defaultChecked,
            ...rest
        },
        ref
    ) => {
        const positionClass = cn({
            [s.top]: position === 'top',
            [s.center]: position === 'center',
        });

        return (
            <div ref={ref} className={cn(s.root, positionClass)}>
                <CheckboxPrimitive.Root
                    className={s.CheckboxRoot}
                    id={id}
                    onCheckedChange={onCheckedChange}
                    {...rest}
                >
                    <CheckboxPrimitive.Indicator
                        className={s.CheckboxIndicator}
                    >
                        <Icon className={s.checkIcon} icon="check" />
                    </CheckboxPrimitive.Indicator>
                </CheckboxPrimitive.Root>
                <label className={cn(s.label, positionClass)} htmlFor={id}>
                    {label}
                </label>
            </div>
        );
    }
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
