'use client';

import React from 'react';
import { ProductVariant } from 'shopify-storefront-api-typings';
import { LinkerComponent, LinkManager } from '../link-manager/LinkManager';
import StaticImage from '../static-image';
import { Button } from '../button';
import cn from 'clsx';
import s from './wishlist-line.module.css';

export type WishlistLineProps = {
    id: string;
    handle: string;
    sku: string;
    title: string;
    originalPrice: number;
    price: string;
    currencyCode: string;
    compareAtPrice: null | string;
    imageSrc: string;
    size: string;
    colour: string;
    selectedVariant: ProductVariant;
    variants: ProductVariant[];
    callbacks: {
        removeFromWishlist: (variant: ProductVariant) => void;
        moveToCart: (variants: ProductVariant[]) => Promise<void>;
        switchVariant: (newVariantID: string) => void;
    };
    compact?: boolean;
    lang: string;
    Linker: LinkerComponent;
};

export function WishlistLine({
    id,
    handle,
    sku,
    title,
    price,
    currencyCode,
    compareAtPrice,
    imageSrc,
    size,
    colour,
    selectedVariant,
    variants,
    callbacks,
    lang,
    Linker = LinkManager,
    compact = false,
}: WishlistLineProps) {
    const rootClassNames = cn(s.root, { [s.compact]: compact });
    const imageDimensions = compact
        ? { width: '80', height: '107' }
        : { width: '160', height: '225' };
    const imageDimensionsString = new URLSearchParams(
        imageDimensions
    ).toString();

    const toMobileRatio = (value: string): string => {
        if (compact) return value;
        const output = parseInt(value) * 0.8;
        return output.toString();
    };

    const currentSize = selectedVariant.selectedOptions.filter(
        (option) => option.name === 'Size'
    )[0]?.value;
    return (
        <div className={rootClassNames} data-sku={sku} data-lineitem-id={id}>
            <Linker
                className={s.imageLink}
                href={`/products/${handle}`}
                lang={lang}
                title={`Go to ${title}`}
            >
                <StaticImage
                    desktopImage={`${imageSrc}?${imageDimensionsString}`}
                    mobileImage={`${imageSrc}?${imageDimensionsString}`}
                    altDescription={title}
                    desktopWidth={imageDimensions.width}
                    desktopHeight={imageDimensions.height}
                    mobileWidth={toMobileRatio(imageDimensions.width)}
                    mobileHeight={toMobileRatio(imageDimensions.height)}
                />
            </Linker>
            <div className={s.details}>
                <div className={s.variantWrapper}>
                    <div className={s.variant}>
                        <div
                            className={cn(
                                'mmds-component-one-detail',
                                s.variantRow
                            )}
                        >
                            <Linker
                                href={`/products/${handle}`}
                                lang={lang}
                                title={`Go to ${title}`}
                                className={cn(
                                    {
                                        [s.compact]: compact,
                                    },
                                    'mmds-component-one-detail'
                                )}
                            >
                                {title}
                            </Linker>
                        </div>
                        <div
                            className={cn(
                                'mmds-component-one-detail',
                                s.variantRow
                            )}
                        >
                            <p className={s.size}>
                                Size: {currentSize}
                                {/*<Select*/}
                                {/*    name={`wishlist-line-${id}-variant`}*/}
                                {/*    id={`wishlist-line-${id}-variant`}*/}
                                {/*    placeholder={'Change'}*/}
                                {/*    value={selectedVariant.id}*/}
                                {/*    className={s.sizeSelect}*/}
                                {/*    onChange={(e) =>*/}
                                {/*        callbacks.switchVariant(e.target.value)*/}
                                {/*    }*/}
                                {/*    options={variants.map((variant) => ({*/}
                                {/*        value: variant.id,*/}
                                {/*        label: variant.selectedOptions.filter(*/}
                                {/*            (option) => option.name === 'Size'*/}
                                {/*        )[0]?.value,*/}
                                {/*    }))}*/}
                                {/*    width="initial"*/}
                                {/*/>*/}
                            </p>

                            {compareAtPrice && (
                                <p className={s.price}>
                                    <span>{price}</span>
                                    <span>{currencyCode}</span>
                                </p>
                            )}
                        </div>
                        <div className={cn('mmds-component-one-detail')}>
                            <p className={s.colour}>Color: {colour}</p>
                        </div>
                    </div>
                    <div className="mmds-component-one-detail">
                        {!!compareAtPrice && (
                            <p className={s.comparePrice}>
                                <span>{compareAtPrice}</span>
                                <span>{currencyCode}</span>
                            </p>
                        )}
                        <p className={s.price}>
                            <span>{price}</span>
                            <span>{currencyCode}</span>
                        </p>
                    </div>
                </div>
                <div className={s.meta}>
                    {selectedVariant.availableForSale && (
                        <Button
                            variant="link_alt"
                            className={s.controlButton}
                            onClick={() =>
                                callbacks.moveToCart([selectedVariant])
                            }
                        >
                            Move to Bag
                        </Button>
                    )}
                    <Button
                        variant="link_alt"
                        className={s.controlButton}
                        onClick={() =>
                            callbacks.removeFromWishlist(selectedVariant)
                        }
                    >
                        Remove
                    </Button>
                </div>
            </div>
        </div>
    );
}
