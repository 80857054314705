import React from 'react';

const CompressionFabrics = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M28 28V4H4V28H28ZM23.2034 23.2034C21.0908 21.4295 18.6269 20.4092 15.9979 20.4092C13.3688 20.4092 10.9092 21.4295 8.79238 23.2034C10.5662 21.0908 11.5865 18.6269 11.5865 15.9979C11.5865 13.3688 10.5662 10.9092 8.79238 8.79238C10.9049 10.5662 13.3688 11.5865 15.9979 11.5865C18.6269 11.5865 21.0866 10.5662 23.2034 8.79238C21.4295 10.9049 20.4092 13.3688 20.4092 15.9979C20.4092 18.6269 21.4295 21.0866 23.2034 23.2034Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default CompressionFabrics;
